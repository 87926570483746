<!-- eslint-disable vue/no-parsing-error -->
<template>
  <b-modal
    :id="`modal-fare-rules-${id}`"
    :title="$t('flight.fareRule')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    no-close-on-backdrop
    :busy="loading"
    @show="openModalHandle"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        @click="cancel"
      >
        {{ $t('close') }}
      </b-button>
    </template>

    <b-tabs pills>
      <!-- ANCHOR Tab Fare Rules Shorten -->
      <b-tab v-if="sourceShowTabFareRuleShorten.includes(trip.source)">
        <template #title>
          <div class="d-flex-center gap-1">
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="m-0"
            />
            <span class="font-weight-bold">{{ $t('flight.fareRules.title') }}</span>
          </div>
        </template>
        <IAmOverlay :loading.sync="loading">
          <app-collapse
            type="border"
            style="max-height: 70vh; overflow: auto; line-height: 24px; white-space: pre; font-family: Calibri, sans-serif;"
          >
            <app-collapse-item
              v-for="(fareRuleItem, fareRuleIndex) in fareRuleTextFromAirline"
              :key="fareRuleIndex"
              title
            >
              <template #header>
                <span
                  class="text-heading-4 text-uppercase fw-700"
                  :class="`${fareRuleItem[0].includes('PENALTIES') ? 'text-danger' : 'text-dark'}`"
                >
                  {{ fareRuleItem[0] }}
                </span>
              </template>
              <div class="position-relative ml-50">
                <b-button
                  v-if="!loading"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-secondary"
                  class="position-absolute p-75 rounded-circle m-25"
                  style="top: 0; right: 0; z-index: 9999;"
                  :disabled="disabledCopy"
                  @click="copyFareRuleText(fareRuleItem.join('\n'))"
                >
                  <feather-icon
                    v-b-tooltip.hover.window
                    icon="CopyIcon"
                    size="21"
                    class="text-dark"
                    :title="$t('reservation.shortenBooking.copyFareRules')"
                  />
                </b-button>
                <div
                  v-for="(textItem, textIndex) in fareRuleItem"
                  :key="textIndex"
                  class="text-wrap"
                  v-html="textItem.replace(/\d+/g, '<strong class=text-info>$&</strong>')"
                />
              </div>
            </app-collapse-item>
          </app-collapse>
          <div v-if="loading">
            Đang tải...
          </div>
          <div v-else-if="!(fareRuleTextFromAirline && fareRuleTextFromAirline.length)">
            Không có điều kiện vé rút gọn từ hãng
          </div>
        </IAmOverlay>
      </b-tab>

      <!-- ANCHOR Tab Fare Rules Common - Điều kiện vé rút gọn -->
      <b-tab v-if="sourceShowTabFareRulesCommon.includes(trip.source)">
        <template #title>
          <div class="d-flex-center gap-1">
            <feather-icon
              icon="BriefcaseIcon"
              size="20"
              class="m-0"
            />
            <span class="font-weight-bold">{{ $t('flight.fareRules.titleFromAirline') }}</span>
          </div>
        </template>

        <BCard
          no-body
          class="p-1 m-0"
          style="line-height: 1.7;"
        >
          <!-- SECTION - DKiện hành lý -->
          <div class="d-flex font-weight-bold">
            <!-- NOTE: international -->
            <div
              v-if="!trip.domestic"
              class="text-truncate d-flex-center"
            >
              <!-- ANCHOR: '1G', 'AK', 'TH', '1A' -->
              <b
                v-if="['1G', 'AK', 'TH', '1A'].includes(trip.source)"
                class="text-dark font-weight-bold"
              >
                <div
                  v-if="resolveBaggage1GAKComputed.carryBag"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ resolveBaggage1GAKComputed.carryBag }}
                </div>
                <div
                  v-if="resolveBaggage1GAKComputed.checkinBag || !['1G', '1A'].includes(trip.source)"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <!-- <pre class="p-2 text-wrap">{{ resolveBaggage1GAKComputed }}</pre> -->
                  <LuggageIcon :no-luggage="!(resolveBaggage1GAKComputed.checkinBag)" />

                  {{ $t('flight.checkinBaggage') }}:
                  {{ resolveBaggage1GAKComputed.checkinBag || 'Không bao gồm' }}
                </div>
              </b>

              <!-- ANCHOR: 1S quoc te -->
              <!-- <b
                v-if="['1S'].includes(trip.source)"
                class="text-dark font-weight-bold"
              >
                <div
                  v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, fareOption.groupClass).boardingBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                                      fareOption.groupClass).boardingBaggage
                  }}
                </div>
                <div
                  v-if="fareOption.baggageAllowances && fareOption.baggageAllowances.length"
                  class="d-flex justify-content-start align-items-start gap-1"
                >
                  <b-img
                    blank-color="#ccc"
                    style="height: 24px;"
                    :src="require(`@icons/luggage.svg`)"
                  />
                  {{ $t('flight.checkinBaggage') }}:
                  {{
                    `${fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].numBaggagePieces > 0 ? fareOption.baggageAllowances[0].numBaggagePieces + 'PC' : ''}${fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].numBaggagePieces > 1 ? 's' : ''}`
                  }}

                  <span
                    v-if="fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].descriptions && fareOption.baggageAllowances[0].descriptions.length"
                  >
                    (<span
                      v-for="(desc, descIndex) in fareOption.baggageAllowances[0].descriptions"
                      :key="descIndex"
                    >
                      {{ desc }}
                    </span>)
                  </span>

                  <span
                    v-if="['VN1A'].includes(trip.source)"
                    class="text-wrap"
                  >
                    {{ `${fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].quantity > 0 ? fareOption.baggageAllowances[0].quantity + 'PC' : ''}${fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].quantity > 1 ? 's' : ''}` }}

                    <span v-if="fareOption.baggageAllowances && fareOption.baggageAllowances[0] && fareOption.baggageAllowances[0].freeText && fareOption.baggageAllowances[0].freeText.length">
                      (<span> {{ fareOption.baggageAllowances[0].freeText }} </span>)
                    </span>
                  </span>
                </div>

                <div
                  v-else-if="fareRulesAirline(trip.segments[0].operating || trip.airline, fareOption.groupClass).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <b-img
                    blank-color="#ccc"
                    style="height: 24px;"
                    :src="require(`@icons/luggage.svg`)"
                  />
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                                      fareOption.groupClass).checkinBaggage }}
                </div>
              </b> -->

              <!-- ANCHOR: VN1A quoc te -->
              <b
                v-if="['VN1A'].includes(trip.source)"
                class="text-dark font-weight-bold"
              >
                <div
                  v-if="resolveBaggageVN1A(fareOption.baggageAllowances).carryBag"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ resolveBaggageVN1A(fareOption.baggageAllowances).carryBag }}
                </div>
                <div
                  v-if="resolveBaggageVN1A(fareOption.baggageAllowances).checkinBag"
                  class="d-flex-center justify-content-start gap-1"
                >

                  <LuggageIcon :no-luggage="!(resolveBaggageVN1A(fareOption.baggageAllowances).checkinBag)" />
                  {{ $t('flight.checkinBaggage') }}:
                  {{ resolveBaggageVN1A(fareOption.baggageAllowances).checkinBag }}
                </div>
              </b>

              <!-- ANCHOR VU Quoc te -->
              <b
                v-if="['VU'].includes(trip.source)"
                class="text-dark font-weight-bold"
              >
                <div
                  v-if="fareRulesAirline('VU', fareOption.fareType).boardingBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('VU', fareOption.fareType).boardingBaggage }}
                </div>
                <div
                  v-if="fareRulesAirline('VU', fareOption.fareType).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VU', fareOption.fareType).checkinBaggage))" />

                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('VU', fareOption.fareType).checkinBaggage }}
                </div>
              </b>

              <!-- ANCHOR: VJ quoc te -->
              <b v-if="['VJ'].includes(trip.source)">
                <!-- NHOM 2-->
                <div v-if="[trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ2.includes(item))">
                  <div
                    v-if="fareRulesAirline('VJ_2', fareOption.groupClass).boardingBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="20"
                      class="text-warning"
                      style="margin: 2px;"
                    />
                    {{ $t('flight.boardingBaggage') }}:
                    {{ fareRulesAirline('VJ_2', fareOption.groupClass).boardingBaggage }}
                  </div>
                  <div
                    v-if="fareRulesAirline('VJ_2', fareOption.groupClass).checkinBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ_2', fareOption.groupClass).checkinBaggage))" />

                    {{ $t('flight.checkinBaggage') }}:
                    {{ fareRulesAirline('VJ_2', fareOption.groupClass).checkinBaggage }}
                  </div>
                </div>

                <div v-else-if="[trip.arrival.IATACode, trip.departure.IATACode].some(item => iataCodeVJ3.includes(item))">
                  <div
                    v-if="fareRulesAirline('VJ_3', fareOption.groupClass).boardingBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="20"
                      class="text-warning"
                      style="margin: 2px;"
                    />
                    {{ $t('flight.boardingBaggage') }}:
                    {{ fareRulesAirline('VJ_3', fareOption.groupClass).boardingBaggage }}
                  </div>
                  <div
                    v-if="fareRulesAirline('VJ_3', fareOption.groupClass).checkinBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ_3', fareOption.groupClass).checkinBaggage))" />

                    {{ $t('flight.checkinBaggage') }}:
                    {{ fareRulesAirline('VJ_3', fareOption.groupClass).checkinBaggage }}
                  </div>
                </div>
                <!-- NHOM 1 -->
                <div v-else>
                  <div
                    v-if="fareRulesAirline('VJ', fareOption.groupClass).boardingBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="20"
                      class="text-warning"
                      style="margin: 2px;"
                    />
                    {{ $t('flight.boardingBaggage') }}:
                    {{ fareRulesAirline('VJ', fareOption.groupClass).boardingBaggage }}
                  </div>
                  <div
                    v-if="fareRulesAirline('VJ', fareOption.groupClass).checkinBaggage"
                    class="d-flex-center justify-content-start gap-1"
                  >
                    <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('VJ', fareOption.groupClass).checkinBaggage))" />

                    {{ $t('flight.checkinBaggage') }}:
                    {{ fareRulesAirline('VJ', fareOption.groupClass).checkinBaggage }}
                  </div>
                </div>
              </b>

              <!-- ANCHOR: QH Quoc te -->
              <b v-if="['QH'].includes(trip.source)">
                <div
                  v-if="fareRulesAirline(resolveQHInternational(trip.arrival.IATACode, trip.departure.IATACode), fareOption.fareType).boardingBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline(resolveQHInternational(trip.arrival.IATACode, trip.departure.IATACode), fareOption.fareType).boardingBaggage }}
                </div>
                <div
                  v-if="fareRulesAirline(resolveQHInternational(trip.arrival.IATACode, trip.departure.IATACode), fareOption.fareType).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline(resolveQHInternational(trip.arrival.IATACode, trip.departure.IATACode), fareOption.fareType).checkinBaggage))" />

                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline(resolveQHInternational(trip.arrival.IATACode, trip.departure.IATACode), fareOption.fareType).checkinBaggage }}
                </div>
              </b>
            </div>

            <!-- NOTE: domestic -->
            <div
              v-else
              class="text-truncate d-flex-center"
            >
              <!-- ANCHOR: baggage for QH_VCS (Con Dao) -->
              <b v-if="trip.source === 'QH' && [trip.departure.IATACode, trip.arrival.IATACode].includes('VCS')">
                <div
                  v-if="fareRulesAirline('QH_VCS', fareOption.fareType).boardingBaggage && fareRulesAirline('QH_VCS', fareOption.fareType).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline('QH_VCS', fareOption.fareType).boardingBaggage }}
                </div>
                <div
                  v-if="fareRulesAirline('QH_VCS', fareOption.fareType).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <LuggageIcon :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline('QH_VCS', fareOption.fareType).checkinBaggage))" />

                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline('QH_VCS', fareOption.fareType).checkinBaggage }}
                </div>
              </b>
              <!-- ANCHOR: else -->
              <b v-else>
                <div
                  v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).boardingBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="20"
                    class="text-warning"
                    style="margin: 2px;"
                  />
                  {{ $t('flight.boardingBaggage') }}:
                  {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                                      ['QH', 'VU'].includes(trip.source) ? fareOption.fareType :
                                      fareOption.groupClass).boardingBaggage }}
                </div>
                <div
                  v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).checkinBaggage"
                  class="d-flex-center justify-content-start gap-1"
                >
                  <LuggageIcon
                    :no-luggage="(['NONE', 'Không bao gồm'].includes(fareRulesAirline(trip.segments[0].operating || trip.airline,
                                                                                      ['QH', 'VU'].includes(trip.source) ? fareOption.fareType :
                                                                                      fareOption.groupClass).checkinBaggage))"
                  />
                  {{ $t('flight.checkinBaggage') }}:
                  {{ fareRulesAirline(trip.segments[0].operating || trip.airline,
                                      ['QH', 'VU'].includes(trip.source) ? fareOption.fareType :
                                      fareOption.groupClass).checkinBaggage }}
                </div>
              </b>
            </div>
          </div>
          <!-- !SECTION - END DKiện hành lý -->

          <!-- SECTION Hoàn vé -->
          <div class="d-flex font-weight-bold">
            <div v-if="['1G', 'AK'].includes(trip.source)">
              <div
                v-if="fareOption.cancelPenalties && fareOption.cancelPenalties.length && !fareOption.cancelPenalties[0].nonRefundable"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-refund.svg`)"
                />
                <span>
                  {{ $t('flight.fareRules.refundableAllow') }}
                  (<span class="fw-700 text-airline">{{ $t('flight.fareRules.contactBooker') }}</span>).
                  <!-- <span v-html="mappingPenalties(fareOption.cancelPenalties)" /> -->
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-refund.svg`)"
                />
                <span class="text-danger">
                  {{ $t('flight.fareRules.noRefundable') }}
                </span>
              </div>
            </div>

            <div v-else-if="['1A', '1B'].includes(trip.source)">
              <div
                v-if="fareOption.refundPenalties && fareOption.refundPenalties.length && !fareOption.refundPenalties[0].nonRefundable"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-refund.svg`)"
                />
                <span>
                  {{ $t('flight.fareRules.refundableAllow') }}
                  (<span class="fw-700 text-airline">{{ $t('flight.fareRules.contactBooker') }}</span>).
                  <!-- <span v-html="mappingPenalties(fareOption.cancelPenalties)" /> -->
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-refund.svg`)"
                />
                <span class="text-danger">
                  {{ $t('flight.fareRules.noRefundable') }}
                </span>
              </div>
            </div>

            <!-- Hoàn vé nội địa -->
            <div v-else-if="trip.domestic">
              <div
                v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass)
                  ? !['', 'NONE', false].includes(fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).refund)
                  : !fareOption.noRefund"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-refund.svg`)"
                />
                <span>
                  {{ trip.source !== 'VJ' ? $t('flight.fareRules.refundable') : $t('flight.fareRules.refundableIdentify') }}:
                  <span>{{ fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass)
                    ? fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).refund
                    : $t('flight.fareRuleTimeStatus.refundable') }}</span>
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-refund.svg`)"
                />
                <span class="text-danger">
                  {{ $t('flight.fareRules.noRefundable') }}
                </span>
              </div>
            </div>
          </div>
          <!-- !SECTION END Hoàn vé -->

          <!-- SECTION Đổi ngày bay -->
          <div class="d-flex font-weight-bold">
            <div v-if="['1G', 'AK', '1A', '1B'].includes(trip.source)">
              <div
                v-if="fareOption.changePenalties && fareOption.changePenalties.length && !fareOption.changePenalties[0].nonRefundable"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-change.svg`)"
                />
                <span>
                  {{ $t('flight.fareRules.reschedulableAllow') }}
                  (<span class="fw-700 text-airline">{{ $t('flight.fareRules.contactBooker') }}</span>).
                  <!-- <span v-html="mappingPenalties(fareOption.changePenalties)" /> -->
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-change.svg`)"
                />
                <span class="text-danger">
                  {{ $t('flight.fareRules.noReschedulable') }}
                </span>
              </div>
            </div>

            <!-- Đổi ngày bay nội địa -->
            <div v-else-if="trip.domestic">
              <div
                v-if="!['NONE', false, ''].includes(fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).reschedule)"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-change.svg`)"
                />
                <span>
                  {{ $t('flight.fareRules.reschedulable') }}:
                  <span>{{ fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareOption.fareType : fareOption.groupClass).reschedule }}</span>
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-change.svg`)"
                />
                <span class="text-danger">
                  {{ $t('flight.fareRules.noReschedulable') }}
                </span>
              </div>
            </div>
          </div>
          <!-- !SECTION END Đổi ngày bay -->

          <!-- SECTION Noshow -->
          <div class="d-flex font-weight-bold">
            <!-- '1A' -->
            <div v-if="['1G'].includes(trip.source)">
              <div
                v-if="fareOption.noshowPenalties && fareOption.noshowPenalties.length && !fareOption.noshowPenalties[0].nonRefundable"
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/can-change.svg`)"
                />
                <span>
                  {{ $t(`flight.fareRuleTimeStatus.noshow`) }}: {{ $t('flight.fareRules.allow') }}
                  (<span class="fw-700 text-airline">{{ $t('flight.fareRules.contactBooker') }}</span>).
                  <!-- <span v-html="mappingPenalties(fareOption.noshowPenalties)" /> -->
                </span>
              </div>

              <div
                v-else
                class="d-flex-center"
              >
                <b-img
                  class="mr-25"
                  blank-color="#ccc"
                  style="height: 24px;"
                  :src="require(`@/assets/other-icon/no-change.svg`)"
                />
                <span class="text-danger">
                  {{ $t(`flight.fareRuleTimeStatus.notNoshow`) }}
                </span>
              </div>
            </div>
          </div>
          <!-- SECTION END Noshow -->
        </BCard>
      </b-tab>

      <template #tabs-end>
        <div class="flex-fill d-flex-center justify-content-end flex-wrap">
          <b-button
            v-if="['VN1A', 'VU', 'QH', 'VJ'].includes(trip.source) ||
              (['F1'].includes(trip.source) && airlineHaveLinkFareRule.includes(trip.airline))"
            variant="flat-info"
            class="px-75 py-50 rounded-lg text-nowrap"
            style="text-decoration: underline"
            @click.prevent.stop="openLinkFareRules(trip.source, trip.airline)"
          >
            {{ $t('flight.linkFareRule') }}
          </b-button>
        </div>
      </template>
    </b-tabs>
  </b-modal>
</template>
<script>
import {
  BTab, BTabs, BCard, BButton, VBTooltip, BImg,
} from 'bootstrap-vue'
import { ref, toRefs, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useClipboard } from '@vueuse/core'

import { iataCodeVJ2, iataCodeVJ3 } from '@/constants/selectOptions'
import store from '@/store'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

import {
  formatDateMonth,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BImg,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    LuggageIcon: () => import('./LuggageIcon.vue'),
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    trip: {
      type: Object,
      default: () => { },
    },
    fareOption: {
      type: Object,
      default: () => { },
    },
    fareValue: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const loading = ref(false)
    const { trip, fareOption, fareValue } = toRefs(props)
    const fareRuleTextFromAirline = ref('')

    const sourceShowTabFareRuleShorten = ['VN1A', '1G', '1A']
    const sourceShowTabFareRulesCommon = ['VN1A', 'VU', 'QH', 'VJ', '1G', 'AK', 'TH', '1A']

    const {
      getFareRules,
      resolveBaggage1G,
      resolveBaggageVN1A,
      mappingPenalties,
      resolveQHInternational,
    } = useBookingHandle()
    const { copy } = useClipboard()
    async function openModalHandle() {
      loading.value = true
      fareRuleTextFromAirline.value = null
      let payload

      if (['VN1A',
      // 'F1' // a tuấn bảo bỏ fareRule F1 https://discord.com/channels/1054696448110903327/1276378197373616128/1276378200938778676
      ].includes(trip.value.source)) {
        payload = {
          source: trip.value.source,
          clientId: trip.value?.clientId || undefined,
          itinerary: {
            airline: trip.value.airline,
            departDate: formatDateMonth(trip.value.departure.at, 'YYYY-MM-DD'),
            departure: trip.value.departure.IATACode,
            arrival: trip.value.arrival.IATACode,
            fareBasisCode: fareOption.value.fareBasisCode.split(' | ')[0],
          },
          ...(['VN1A'].includes(trip.value.source) && { fareValue: fareOption.value.fareValue }),
        }
      } else if (['1G', '1A'].includes(trip.value.source)) {
        payload = {
          source: trip.value.source,
          fareValue: fareValue.value || fareOption.value.fareValue,
        }
      }
      if (payload) {
        const res = await getFareRules(payload)
        const isSource1AOrSourceVN1A = res && (res?.source === 'VN1A' || res?.source === '1A')
        const isF1 = res && (res?.source === 'F1')

        if (isSource1AOrSourceVN1A) {
          fareRuleTextFromAirline.value = res?.fares[0]?.fareRules
            .map(item => {
              const splitValues = typeof item?.value === 'string' ? item?.value?.split('\n') : (item?.value ?? [])
              return [item?.key].concat(splitValues)
            })
            .filter(Boolean)
        } else if (isF1) {
          fareRuleTextFromAirline.value = []
          // eslint-disable-next-line no-unused-expressions
          res?.fares.forEach(item => {
            // eslint-disable-next-line prefer-template
            const headerStr = item.airline + ' ' + item.fareBasisCode
            fareRuleTextFromAirline.value.push(headerStr)
            // eslint-disable-next-line prefer-template
            item.fareRules.forEach(frItem => {
              const valueSplit = frItem.value.map(val => val.split('\\n')).flat()
              if (valueSplit.length > 1) {
                fareRuleTextFromAirline.value.push(frItem.key)
                valueSplit.forEach(v => fareRuleTextFromAirline.value.push(v))
              } else {
                // eslint-disable-next-line prefer-template
                fareRuleTextFromAirline.value.push(frItem.key + ' ' + valueSplit[0])
              }
            })
          })
        } else {
          fareRuleTextFromAirline.value = res.sort(a => a.startsWith('PENALTIES') ? -1 : 1).map(item => item.split('\n'))
        }
      }

      loading.value = false
    }
    const disabledCopy = ref(false)
    function copyFareRuleText(text = null) {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(text ?? fareRuleTextFromAirline.value.join('\n'))
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }
    const airlineHaveLinkFareRule = ref(['AK', '5J', 'TW', 'DD', 'SG', 'JQ', 'TR'])
    function openLinkFareRules(source, airline) {
      let getSource = ['VN1A'].includes(source) ? '1S' : source
      if (getSource === 'F1') {
        getSource = airline
      }
      const link = store.getters['globalConfig/getLinkFareRules'](getSource)
      window.open(link)
    }

    function fareRulesAirline(airline, cabin) {
      return store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
    }

    const resolveBaggage1GAKComputed = computed(() => {
      if (['AK', '1G', 'TH', '1A'].includes(trip.value.source)) return resolveBaggage1G(fareOption.value.baggageAllowances, trip.value.source)
      return {}
    })

    return {
      openModalHandle,
      loading,
      fareRuleTextFromAirline,
      copyFareRuleText,
      disabledCopy,
      openLinkFareRules,
      fareRulesAirline,
      iataCodeVJ2,
      iataCodeVJ3,
      resolveQHInternational,
      resolveBaggage1GAKComputed,
      resolveBaggageVN1A,
      mappingPenalties,
      airlineHaveLinkFareRule,
      sourceShowTabFareRuleShorten,
      sourceShowTabFareRulesCommon,
    }
  },
}
</script>
